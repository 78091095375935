import * as React from 'react';
import '../styles/404.scss';
import { Box, Stack, Text } from '@chakra-ui/react';
import { ArrowBackIcon, ArrowForwardIcon } from '@chakra-ui/icons';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import SEO from '../components/seo';
import Layout from '../components/layout';

// markup
const NotFoundPage = () => {
  return (
    <Layout>
      <SEO
        title="Company | Ostara"
        description="NUTRIENT MANAGEMENT SOLUTIONS"
        image="https://pacemediafiles.s3.us-west-2.amazonaws.com/ostara/ostara_logo.png"
      />
      <main className="page-not-found">
        <Stack
          border="solid 1px #044606"
          borderRadius={15}
          paddingX={[6, 6, 14]}
          paddingY={[8, 8, 10]}
          maxW={573}
          marginX="auto"
          className="page-not-found__text"
        >
          <Text textAlign="center" color="#044606" marginBottom={3}>
            We can’t find the page you are looking for.
            <br />
            Sorry for the inconvenience.
          </Text>
          <Text textAlign="center" color="#044606" fontWeight="bold">
            <Link to="/">
              <Box display="inline-flex" alignItems="center">
                <ArrowBackIcon color="#044606" marginRight={2} />
                Go Back
              </Box>
            </Link>
          </Text>
        </Stack>
        <StaticImage
          className="page-not-found__line"
          placeholder="transparent"
          src="../images/404-line.svg"
          alt=""
        />
        <Box className="page-not-found__cards">
          <div className="page-not-found__card">
            <StaticImage
              className="page-not-found__card-image"
              placeholder="transparent"
              src="../images/404-img01.png"
              alt=""
            />
            <Link to="/product" className="page-not-found__link">
              <Text fontWeight={300} color="#044606">
                Fertilizers
                <ArrowForwardIcon color="#044606" />
              </Text>
            </Link>
          </div>
          <div className="page-not-found__card">
            <StaticImage
              className="page-not-found__card-image"
              placeholder="transparent"
              src="../images/404-img02.png"
              alt=""
            />
            <Link to="/technology" className="page-not-found__link">
              <Text fontWeight={300} color="#044606">
                Nutrient
                <br />
                Recovery
                <ArrowForwardIcon color="#044606" />
              </Text>
            </Link>
          </div>
        </Box>
      </main>
    </Layout>
  );
};

export default NotFoundPage;
